import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export const animateWithGsap = (target, animationProps, scrollProps) => {
  gsap.to(target, {
    ...animationProps,
    scrollTrigger: {
      trigger: target,
      toggleActions: "restart reverse restart reverse", //ENTER LEAVE ENTERBACK LEAVEBACK
      start: "top 85%",
      ...scrollProps,
    },
  });
};

export const animateWithGsapTimeline = (
  timeline,
  rotationRef,
  rotationState,
  firstView,
  secondView,
  animationProps
) => {
  timeline.to(rotationRef.current.rotation, {
    y: rotationState,
    duration: 1,
    ease: "power1.inOut",
  });

  timeline.to(
    firstView,
    {
      ...animationProps,
      ease: "power2.inOut",
    },
    "<" //insert the animatioin at the start of the previous animation
  );

  timeline.to(
    secondView,
    {
      ...animationProps,
      ease: "power2.inOut",
    },
    "<" //insert the animatioin at the start of the previous animation
  );
};
