import { OrbitControls, PerspectiveCamera, View } from "@react-three/drei";
import * as THREE from "three";
import React, { Suspense } from "react";
import Lights from "./Lights";
import IPhoneModel from "./IPhoneModel";
import Loader from "./Loader";

const ModelView = ({
  index,
  groupRef,
  gsapType,
  controlRef,
  setRotationState,
  item,
  size,
}) => {
  return (
    <View
      index={index}
      id={gsapType}
      className={`w-full h-full absolute ${index === 2 ? "right-[-100%]" : ""}`}
    >
      {/* Ambient light */}
      <ambientLight intensity={0.3} />
      {/* Perspective camera that simulates human eye */}
      <PerspectiveCamera makeDefault position={[0, 0, 4]} />
      <Lights />

      {/* ORBIT controls allows to move the camera around the scene using mouse */}
      <OrbitControls
        makeDefault
        ref={controlRef}
        enableZoom={false}
        enablePan={false}
        rotateSpeed={0.5}
        target={new THREE.Vector3(0, 0, 0)} //target the center of the screen
        onEnd={() => setRotationState(controlRef.current.getAzimuthalAngle())}
      />
      <group
        ref={groupRef}
        name={`${index === 1 ? "small" : "large"}`}
        position={[0, 0, 0]}
      >
        <Suspense fallback={<Loader />}>
          <IPhoneModel
            scale={index === 1 ? [15, 15, 15] : [17, 17, 17]}
            item={item} //contains info about model
            size={size}
          />
        </Suspense>
      </group>
    </View>
  );
};

export default ModelView;
